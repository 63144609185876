<template>
  <div  class="homePage" :class="isBlack != 'true'?'whiteBox':''">
    <div class="top_header">
      <div class="header">
        <div class="header_left">
          <img src="../../static/demo/logo.png" class="logoimg" style="height: 42px;width: auto;" />
          <div class="leftname" v-if="false">
          	HKEX
          </div>
        </div>
        <div class="header_right">
          <div class="action">
            UID:{{userid}}
          </div>
        </div>
      </div>
    </div>
  <div class="content">
    <div class="left-bg1"></div>
    <div class="left-bg2"></div>
    <div class="tab4-content1">
      <span class="title1"><img src="../../static/demo/tab4_1.svg" />{{ $t('tab4.text1') }}</span>
      <div class="balance">
        <span>{{ Number(balance).toFixed(4) }}</span>
        <span class="tab4-content1-balance-baifenbi">0.00%</span>
      </div>
      <div class="info-list">
        <div class="info">
          <span>{{ $t('tab4.text2') }}</span>
          <span>$0.0000000</span>
        </div>
        <div class="info">
          <span>{{ $t('tab4.text3') }}</span>
          <span>$0.00</span>
        </div >
        <div class="info">
          <span>{{ $t('tab4.text4') }}</span>
          <span>0.00%</span>
        </div>
      </div>
    </div>
    <div  class="menus" dir="auto">
      <div  class="menu"  :class="active === 0? 'menu-active': ''" @click="active = 0">{{ $t('tab4.text5') }}</div>
      <div  class="menu" v-if="false" :class="active === 1? 'menu-active': ''" @click="active = 1">{{ $t('tab4.text6') }}</div>
      <div  class="menu" :class="active === 2? 'menu-active': ''" @click="active = 2">{{ $t('tab4.text7') }}</div>
    </div>
    <div v-if="active === 0" class="content-info" dir="auto">
      <div class="title2">{{ $t('tab4.text5') }}</div>
      <div class="input-content" @click="changeContent">
        {{coin}}
        <img src="../../static/demo/arrow_down.36c10fef.svg" v-if="!isDrown"  alt="">
        <img src="../../static/demo/arrow_up.4af92010.svg" v-if="isDrown" alt="" >
        <div class="day-lists" v-if="isDrown" >
          <div class="day-list" v-for="(item,index) in rechargeList" :key="index" @click="setItem(index)">{{item.title}}</div>
        </div>
      </div>
      <div id="canvas-recharge" class="qr-img" height="140" width="140" style="height: 140px; width: 140px;">
        <img :src="qrcode">
      </div>
      <div class="input-list">
        <div class="inputs inputs-max">
          <div class="lable-max">{{ $t('tab4.text9') }}</div>
          <div class="input-content-max input-content1">
            {{ xianshiaddress }}
            <div class="input-button input-button1" id="copyBtn" @click="copyAddress" data-clipboard-text="3JA7Wz36RoCQSUB3MNHJbqDbPkY3R1vr8K">{{ $t('tab4.text10') }}</div>
          </div>
        </div>
      </div>
      <div class="confirm-button" @click="goToUploadImg">{{ $t('tab4.text11') }}</div>
	  <div class="confirm-button" @click="goToUploadImgs">{{ $t('newadd.text45') }}</div>
      <div class="seed">
        <p>{{ $t('tab4.text12') }}</p>
      </div>
    </div>
    <div  v-if="active === 1" class="content-info" dir="auto">
      <div class="title2">{{ $t('tab4.text13') }}</div>
      <div class="content-info-list">
        <div class="list-title">{{ $t('tab4.text14') }}</div>
        <div class="list-content">
          {{ $t('tab4.text15') }}
        </div>
        <div class="list-title">{{ $t('tab4.text16') }}</div>
        <div class="list-content">
          {{ $t('tab4.text17') }}
        </div>
        <div class="list-title">{{ $t('tab4.text18') }}</div>
        <div class="list-content">
          {{ $t('tab4.text19') }}
        </div>
      </div>
      <div class="confirm-button">{{ $t('tab4.text11') }}</div>
      </div>
    <div v-if="active === 2" class="content-info" dir="auto">
      <div class="title2">{{ $t('tab4.text7') }}</div>
      <div class="input-content-max" @click="changeContent">
        {{ curType.network[curindex].title }}
        <img src="../../static/demo/arrow_down.36c10fef.svg" v-if="!isDrown"  alt="">
        <img src="../../static/demo/arrow_up.4af92010.svg" v-if="isDrown" alt="" >
        <div class="day-lists" v-if="isDrown" >
          <div class="day-list" v-for="(item, index) in curType.network" @click="settxItem(index)">{{item.title}}</div>
        </div>
      </div>
     <div class="input-list">
       <div class="inputs inputs-max">
         <div class="lable-max">{{ $t('tab4.text20') }}</div>
         <div class="input-content-max">
           <input type="text" v-model="amount" onchange="this.value=this.value.replace(/^\D*(\d{0,9}(?:\.\d{0,9})?).*$/g,'$1')" onkeyup="this.value=this.value.replace(/^\D*(\d{0,9}(?:\.\d{0,9})?).*$/g,'$1')" onafterpaste="this.value=this.value.replace(/^\D*(\d{0,9}(?:\.\d{0,9})?).*$/g,'$1')" class="readonly-input">
           <div class="input-button input-buttons" style="background: #1a1a1a;color: #fff;">Max</div>
         </div>
       </div>
     </div>
      <div class="input-list">
        <div class="inputs inputs-max">
          <div class="lable-max">{{ $t('tab4.text9') }}</div>
          <div class="input-content-max">
            <input type="text" v-model="txaddr">
          </div>
        </div>
      </div>
      <div class="confirm-button" @click="submittx">{{ $t('tab4.text22') }}</div>
      <div class="seed">
        <p>{{ $t('tab4.text23') }}</p>
      </div>
    </div>
    <div  class="seeting-main" dir="auto">
      <div class="seeting-list" @click="tooptionOrder">
        <div class="lable">{{ $t('tab4.text24') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list" @click="tocontractOrder">
        <div class="lable">{{ $t('tab4.text25') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list" @click="topledgehistory">
        <div class="lable">{{ $t('tab4.text26') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list" @click="toRecord">
        <div class="lable">{{ $t('tab4.text27') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list" @click="toInvite">
        <div class="lable">{{ $t('tab4.text28') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
	  
	  <div class="seeting-list" @click="toyanzheng">
	    <div class="lable">{{ $t('Authenticator') }}</div>
	    <div class="left-icon">
	      <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
	    </div>
	  </div>
	  
      <div class="seeting-list" @click="toFaq">
        <div class="lable">{{ $t('tab4.text29') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list" @click="tokefutiaozhuan">
        <div class="lable">{{ $t('tab4.text30') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
	  <div class="seeting-list" @click="toLegality">
	    <div class="lable">{{ $t('Legality') }}</div>
	    <div class="left-icon">
	      <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
	    </div>
	  </div>
      <div class="seeting-list" @click="toabout">
        <div class="lable">{{ $t('tab4.text31') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list"  @click="toLanguage">
        <div class="lable">{{ $t('tab4.text32') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list"  @click="loginout">
        <div class="lable">{{ $t('tab4.text33') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
    </div>
  </div>
    <Loading :show="loading"></Loading>
  </div>
</template>

<script>
import { user, purse,recharge,Exchangeshenqing,userkycstatus } from "@/api/tab5";
import { initIndex,loginout } from "@/api/tab4";
import { showNotify, showToast, showConfirmDialog } from "vant";
import { ElLoading } from "element-plus";
import Loading from "@/components/Loading.vue";
import QRCode from "qrcode";
import {balance} from "@/api/tab1";

export default {
  components: {Loading},
  data() {
    return {
      increase: "", //涨幅
      new_price: "", //当前价
      walletaccount: "", //钱包账号
      userid: "", //当前用户id
      isBlack: "true", //默认黑色主题 //true代表黑色主题，false代表白色主题。。。
      setThemeVal: true,
      vipimgsrc: "",
      vipshow: false,
      loading: false,
      active: 0,
      isDrown: false,
      coin: "",
      address: "",
	  xianshiaddress:'',
      qrcode: "",
      balance: "",
	  
	  rechargeList: [],
	  czindex:0,
	  username:'',
	  
	  curType:{
	  	network: [{title: 'ERC20', active: true, type:'USDT'}, {title: 'TRC20', active: false, type:'USDT'}, {title: 'BTC', active: false, type:'BTC'}]
	  },
	  curindex:0,
	  tbchannel:'',
	  amount:'',
	  txaddr:'',
	  
	  sfstatus:0,
    };
  },
  created() {
	var element = document.getElementById('app');
	element.style.height='100%' 
    this.getHomeData();
	this.getBalance();
  },
  methods: {
	submittx(){
		let data = {
			amount:this.amount,
			addr:this.txaddr,
			qianbao:this.txaddr,
			tbchannel:this.tbchannel
		}
		Exchangeshenqing(data).then(res => {
		    const {
		        data: {
		            info,
		            status,
		            url
		        } = {}
		    } = res;
		    showToast({
		        message: info
		    });
			this.amount = ''
			this.txaddr = ''
		})
	},
	settxItem(index){
		this.curindex = index
		this.tbchannel = this.curType.network[this.curindex].type+'_'+this.curType.network[this.curindex].title
	},
    generateQRCode() {
        QRCode.toDataURL(this.address, { errorCorrectionLevel: 'H' }, (err, url) => {
          if (err) console.error(err)
          this.qrcode = url;
        })
    },
    changeContent(){
      this.isDrown = !this.isDrown;
    },
    setItem(index){
		this.czindex = index
		this.coin = this.rechargeList[this.czindex].title
		this.address = this.rechargeList[this.czindex].url
		this.xianshiaddress = this.address.substr(0,5) +'...'+this.address.substr(-14)
    },
    getBalance(){
      balance().then((res)=>{
        this.balance = res.data.url.banlance;
      })
		recharge().then(res => {
			this.rechargeList = res.data.info
			this.coin = this.rechargeList[this.czindex].title
			this.address = this.rechargeList[this.czindex].url
			this.xianshiaddress = this.address.substr(0,5) +'...'+this.address.substr(-14)
			this.generateQRCode();
			//console.log(this.rechargeList[this.czindex].title)
		})
    },
    goToUploadImg(){
      this.$router.push({
        path: "/uploadProof",
        query: {
          currneyName: this.rechargeList[this.czindex].id
        }
      });
    },
	goToUploadImgs(){
		if (window.ethereum) {
			this.$router.push({
			  path: "/uploadProofs"
			});
		} else {
			showToast(this.$t('home.walletnot'))
		}
	},
    async copyAddress() {
      try {
        await navigator.clipboard.writeText(this.address);
        showToast("Copy Success")
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    },
    //获取首页数据
    getHomeData() {
      //            user().then(res => {
      //                // State.homeData = res.data.url
      //                this.increase = Number(res.data.url.banlance).toFixed(2)
      //                this.new_price = Number(res.data.url.change).toFixed(2)

      // //console.log(this.walletaccount)
      //                //console.log(this.increase);
      //                //console.log(this.new_price);
      //            }).catch(err => {
      //                console.log('err-->', err)
      //            })
      //todo 模拟切换主题动画，提升交互效果
	  this.username = localStorage.getItem('username').substr(-8)
	  this.tbchannel = this.curType.network[this.curindex].type+'_'+this.curType.network[this.curindex].title
      /*
	  const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
	  */
	  userkycstatus().then((res) => {
	  	if(res.data.info){
	  		this.sfstatus = res.data.info.idstate
	  	}
	  });
	  
      initIndex()
        .then((res) => {
          //this.increase = Number(res.data.url.eth.change).toFixed(2)
          this.new_price = Number(res.data.url.eth.new_price).toFixed(2);
          //let username = sessionStorage.getItem("username") || "";
		  let username = localStorage.getItem('username') || "";
          //this.walletaccount = new Array(10).join('*') + username.substr(-4)
          this.walletaccount = username;
          this.userid = res.data.url.userid;
          //loading.close();
        })
        .catch((err) => {
          console.log("err-->", err);
        });
      purse()
        .then((res) => {
          //console.log(res.data.info.allfee.eth)
          this.increase = Number(res.data.info.allfee.eth).toFixed(2);
        })
        .catch((err) => {
          console.log("err-->", err);
        });
    },
    towallte() {
      this.$router.push({
        path: "/wallet",
      });
    },
    toNotifications() {
      this.$router.push({
        path: "/notifiCations",
      });
    },
	toyanzheng(){
		if(this.sfstatus==0 || this.sfstatus==3){
			this.$router.push({
			  path: "/verification",
			});
		} else if(this.sfstatus==1){
			showToast(this.$t('newadd.text48'))
		} else if(this.sfstatus==2){
			showToast(this.$t('newadd.text49'))
		}
	},
    toInvite() {
      this.$router.push({
        path: "/invite",
      });
    },
    toLegality() {
      this.$router.push({
        path: "/legality",
      });
    },
    toFaq() {
      this.$router.push({
        path: "/faq",
      });
    },
    toLanguage() {
      this.$router.push({
        path: "/language",
      });
    },
    google(){
        this.$router.push({
        path: "/google",
      });
    },
    loginout() {
      showConfirmDialog({
        title: "",
        message: this.$t("newtext3"),
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("newtext2"),
        confirmButtonColor: '#c0dd2b'
      })
        .then(() => {
				loginout().then((res) => {
				    //sessionStorage.removeItem("username");
				    localStorage.clear();
				    this.$router.push({
						path: "/tab1",
				    });
				}).catch((err) => {
				    console.log("err-->", err);
				});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //主题切换/*0708改*/
    toChangeTheme() {
      let that = this;
      that.setThemeVal = !that.setThemeVal;
      localStorage.setItem("isBlack", that.setThemeVal); //true代表黑色主题，false代表白色主题 | 注意缓存里面为字符串，，非bool型

      //todo 模拟切换主题动画，提升交互效果
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      setTimeout(() => {
        that.isBlack = localStorage.getItem("isBlack");
        //loading.close()
        location.reload();
      }, 1500);

      // showNotify({ type: 'success', message: 'success' });
    },
    setTheme() {
      this.isBlack = "true";
      if (localStorage.getItem("isBlack")) {
        this.isBlack = localStorage.getItem("isBlack"); //true代表黑色主题，false代表白色主题。。。 缓存内的值是字符串
      }
      if (this.isBlack == "true") {
        this.setThemeVal = true;
      } else {
        this.setThemeVal = false;
      }
    },
    toAuthenticator() {
      this.$router.push({
        path: "/authenticator",
      });
    },
	tocontractOrder(){
		this.$router.push({
		  path: "/contractOrder",
		});
	},
	toabout(){
		this.$router.push({
		  path: "/about",
		});
	},
	tooptionOrder(){
		this.$router.push({
		  path: "/optionOrder",
		});
	},
	topledgehistory(){
		this.$router.push({
		  path: "/pledgehistory",
		});
	},
    toRecord() {
      this.$router.push({
        path: "/record",
      });
    },
    toRecords() {
      this.$router.push({
        path: "/record",
        query: {
          active: 2,
        },
      });
    },
	tokefutiaozhuan(){
		window.open(
		  //"https://apple-jp.cc/mobile/index?code=44BlyA9eyXO8ELYdy3FkUkSNN1sLpcZ5jKi4MYA3XOG1NLxUpNmNLWcmz12qHQoQlj2s4iXw%2FzjgvtmomDR0VshLNZtBAxBLxRoBsOnZh8%2FS7vKKZuG9lF1RoudmuJT4XQ&uid=hekx" + this.userid
		  'https://chatlink.wchatlink.com/widget/standalone.html?eid=d383a550c6c84d227e8c66550d01dce5&language=en&metadata={"name":"hkex'+this.userid+'"}'
		);
	},
  },
  mounted() {
    this.setTheme(); /*0708改*/
  },
};
</script>

<style scoped>
.top_header{
    position: fixed;
    top: 0;
    left: 0;
    height: 29px;
    width: 100%;
    background: #000;
    padding: 16px 0;
    z-index: 10;
  }
.header{
    padding: 0 20px;
    height: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
  }
.header_left{
    display: flex;
    align-items: baseline;
  }
.logoimg{
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
.leftname{
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
  }
.header_right{
    //display: flex;
    align-items: center;
    justify-content: space-between;
    width: 124px;
    height: 42px;
    border-radius: 10px;
    background: #1a1a1a;
    position: relative;
    text-align: center;
    line-height: 42px;
  }
.action{
      //display: flex;
      text-align: center;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      //margin-left: 12px;
      //margin-right: 12px;
    }
.homePage {
  background-color: #000;
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  color: #ffffff;
}
.left-bg1{
      background: #d8d8d8;
      mix-blend-mode: normal;
      opacity: .08;
      transform: rotate(-35deg);
      width: 140px;
      height: 235px;
      position: absolute;
      right: -65px;
      top: -61px;
      //z-index: 99;
    }
.left-bg2{
      background: #d8d8d8;
      mix-blend-mode: normal;
      opacity: .08;
      transform: rotate(-35deg);
      width:140px;
      height: 235px;
      position: absolute;
      right: -100px;
      top: -61px;
      //z-index: 99;
    }

.title1{
        display: block;
        font-size: 14px;
        font-width: 500;
        img{
          margin-right: 10px;
        }
      }
.balance{
        display: flex;
        justify-content: start;
        align-items: baseline;
        font-family: DIN Alternate;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        margin-top: 10px;
        border-bottom: 1px solid hsla(0, 0%, 100%, .07);
        padding-bottom: 5px;
		
        span:nth-child(2){
          font-family: Inter;
          font-weight: 600;
          font-size: 14px;
          color: #c2e22a;
          margin-left: 14px;
         }
      }
.info-list{
        display: flex;
        justify-content: start;
        align-items: center;
        margin-top: 25px;
      }
.info{
          width: 33%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
		  
        }
.tab4-content1{
      height: 176px;
      background: #1a1a1a;
      border-radius: 20px;
      padding: 16px;
    }
.menus{
      margin-top: 23px;
      display: flex;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff4d;
      
    }
.menu {
        padding: 10px 17px;
        border-radius: 19px;
      }
.title2{
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 25px;
      }
	.qr-img {
	    background: #fff;
	    border-radius: 10px;
	    margin: auto;
	    margin-top: 24px;
	    padding: 10px;
	    display: block;
	  }
.input-list {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
      }
.lable-max{
            font-weight: 500;
            font-size: 12px;
            color: hsla(0, 0%, 100%, .3);
          }
.input-content-max{
            padding: 0 6px 0 24px;
            width: 100%;
            box-sizing: border-box;
            background: #d8d8d814;
            margin: auto;
            mix-blend-mode: normal;
            border-radius: 10px;
            margin-top: 8px;
            height:56px;
            line-height: 56px;
            display: flex;
            justify-content: space-between;
            color: #fff;
            align-items: center;
            font-weight: 600;
            /* font-size: 14px; */
			font-size: 12px;
            position: relative;
            
          }
.input-button{
              width: 80px;
              height:44px;
              background: #c0dd2b;
              color: #000;
              border-radius:10px;
              font-weight: 400;
              font-size: 14px;
              line-height: 44px;
              text-align: center;
            }
.input-content1 {
            background: #d8d8d84d;
          }
.inputs-max{
        width: 100%;
}
.confirm-button {
        font-weight: 600;
        font-size: 16px;
        color: #000;
        //width: 100%;
        height: 48px;
        background: #c0dd2b;
        border-radius: 13px;
        text-align: center;
        line-height: 48px;
        margin-top: 24px;
      }
.seed{
        margin-top: 24px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff4d;
      }
.day-lists{
          z-index: 10;
          width: 100%;
          max-height: 168px;
          overflow-y: auto;
          position: absolute;
          top: 62px;
          border-radius: 10px;
          background: #292929fc;
          color: #fff;
          left: 0;
        
        }
.day-list {
          height: 56px;
          width: 104px;
          padding: 0 16px 0 20px;
        }
.readonly-input{
          background: none;
          width: calc(100% - 24px);
          cursor: text;
          outline: none;
          border: none;
        }
	input{
          background: none;
          width: calc(100% - 24px);
          cursor: text;
          outline: none;
          border: none;
        }
.input-content {
        background: #d8d8d814;
        margin: auto;
        mix-blend-mode: normal;
        border-radius: 10px;
        margin-top: 8px;
        height: 56px;
        width: 104px;
        line-height: 56px;
        padding: 0 16px 0 20px;
        display: flex;
        justify-content: space-between;
        color: #fff;
        align-items: center;
        font-weight: 600;
        /* font-size: 14px; */
		font-size: 12px;
        position: relative;

      }
.content-info{
      min-height: 369px;
      padding: 25px 20px;
      background: #1a1a1a;
      border-radius: 21px;
      margin-bottom: 16px;
      margin-top: 24px;
      position: relative;
    }
.content-info-list{
      }
.list-title {
          color: #fff;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 8px;
        }
.list-content {
          color: #fff;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          opacity: .3;
          margin-bottom: 16px;
        }
.content-info:before {
      content: "";
      display: inline-block;
      background: #c2e22a;
      border-radius: .05rem;
      width: 5px;
      height: 24px;
      top: 25px;
      left: 0;
      position: absolute;
    }
.content{
    width: 90%;
    margin: auto;
    margin-top: 61px;
    padding-bottom: 90px;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
  }
.seeting-list{
        height: 54px;
        line-height: 54px;
        font-weight: 500;
        font-size: 16px;
        padding: 0 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 12px;
        background: #1a1a1a;
        border-radius: 20px;
      }
.seeting-list:before {
        content: "";
        display: inline-block;
        width: 3px;
        height: 22px;
        background: #c0dd2b;
        position: absolute;
        left: 25px;
        top: 16px;
        border-radius: 3px;
      }
.lable {
        margin-left: 15px;
      }
.left-icon{
        height: 100%;
        display: flex;
        align-items: center;
      }
.seeting-main {
      margin: auto;
      margin-top: 20px;
    }
.menu-active {
  background: #1a1a1a;
  color: #fff;
}
.tab4-content1-balance-baifenbi{
	font-family: Inter;
	font-weight: 600;
	font-size: 14px;
	color: #c2e22a;
	margin-left: 14px;  
  }
</style>